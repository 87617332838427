@import '../params/index.scss'; 

.cont-marca {
    position: absolute;
    bottom: 100px;
    right: 130px;
    transition: 0.3s all;
    .marca {
        font-family: 'Lato', sans-serif;
        font-size: 25px;
        line-height: 40px;
        color: $colorText;
        position: relative;
        width: 230px;
        float: right;
        margin-top: 40px;
        transform: translateX(-30px);
        max-width: 100px;
        &__container {
            font-weight: 600;
            padding: 0 20px;
        
            &:before {
                content: '[';
                left: 0;
            }
            &:after {
                content: ']';
                position: absolute;
                right: 0;
            }
            &:after, &:before {
                position: absolute;
                top: -3px;
                color: $color2;
                font-size: 42px;
                line-height: 40px;
                -webkit-animation-name: opacity;
                -webkit-animation-duration: 2s;
                -webkit-animation-iteration-count: infinite;
                animation-name: opacity;
                animation-duration: 2s;
                animation-iteration-count: infinite;
            }
    
            &__text {
                display: inline;
                float: left;
                margin: 0;
            }

            &__list {
                margin-top: 0;
                padding-left: 290px;
                text-align: left;
                list-style: none;
                -webkit-animation-name: change;
                -webkit-animation-duration: 30s;
                -webkit-animation-iteration-count: infinite;
                animation-name: change;
                animation-duration: 30s;
                animation-iteration-count: infinite;

                &__item {
                    line-height:40px;
                    margin:0;
                }
            }
        }
        @media(max-width: 480px) {
            margin-top: 0px;
        }
    }
    
}

// .cont-marca-movil {
//     display: none;
//     position: relative;
//     background: #000;
//     padding: 10px;
//     @media(max-width: 460px) {
//         display: block;
//         position: fixed;
//         top: 0px;
//         width: 100%;
//         height: fit-content;
//         right: 0px;
//     }
//     .marca {
//         transform: translateX(0px);
//         width: 77px;
//     }
// }
// .cont-marca {
//     @media(max-width: 460px) {
//         display: none;
//     }
// }
@-webkit-keyframes opacity {
0%, 100% {opacity:0;}
50% {opacity:1;}
}
  
@-webkit-keyframes change {
0%, 12.66%, 100% {transform:translate3d(0,0,0);}
16.66%, 29.32% {transform:translate3d(0,-25%,0);}
33.32%,45.98% {transform:translate3d(0,-50%,0);}
49.98%,62.64% {transform:translate3d(0,-75%,0);}
66.64%,79.3% {transform:translate3d(0,-50%,0);}
83.3%,95.96% {transform:translate3d(0,-25%,0);}
}

@-o-keyframes opacity {
0%, 100% {opacity:0;}
50% {opacity:1;}
}
  
@-o-keyframes change {
0%, 12.66%, 100% {transform:translate3d(0,0,0);}
16.66%, 29.32% {transform:translate3d(0,-25%,0);}
33.32%,45.98% {transform:translate3d(0,-50%,0);}
49.98%,62.64% {transform:translate3d(0,-75%,0);}
66.64%,79.3% {transform:translate3d(0,-50%,0);}
83.3%,95.96% {transform:translate3d(0,-25%,0);}
}
  
@-moz-keyframes opacity {
0%, 100% {opacity:0;}
50% {opacity:1;}
}
  
@-moz-keyframes change {
0%, 12.66%, 100% {transform:translate3d(0,0,0);}
16.66%, 29.32% {transform:translate3d(0,-25%,0);}
33.32%,45.98% {transform:translate3d(0,-50%,0);}
49.98%,62.64% {transform:translate3d(0,-75%,0);}
66.64%,79.3% {transform:translate3d(0,-50%,0);}
83.3%,95.96% {transform:translate3d(0,-25%,0);}
}
  
@keyframes opacity {
0%, 100% {opacity:0;}
50% {opacity:1;}
}
  
@keyframes change {
0%, 12.66%, 100% {transform:translate3d(0,0,0);}
16.66%, 29.32% {transform:translate3d(0,-25%,0);}
33.32%,45.98% {transform:translate3d(0,-50%,0);}
49.98%,62.64% {transform:translate3d(0,-75%,0);}
66.64%,79.3% {transform:translate3d(0,-50%,0);}
83.3%,95.96% {transform:translate3d(0,-25%,0);}
}

.content__container__img {
    max-width: 100px;
}