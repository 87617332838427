.box-responsive {
    height: 400px;
    width: 600px;
}

.intro {
    position: absolute;
    width: 100%;
    height: 100%;
    // background-color: #ab33ab;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 1;
    // animation: intro 0.6s ;
    // animation-delay: 13s;
  }
  
  .intro__exterior {
    border-radius: 15px;
    background-color: orange;
    width: 120px;
    height: 222px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: intro__exterior 12s infinite;
    animation-delay: 1s;
  }
  
  .intro__interior {
    border-radius: 15px;
    background-color: #333333;
    width: 95%;
    height: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: intro__interior 12s infinite;
    animation-delay: 1s;
  }
  
  .intro__interior__text {
    color: orange;
    border-right: 1px solid;
    animation: intro__interior__text 12s infinite;
    animation-delay: 1s;
  }
  
  .intro__interior__text > div {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    width: 0;
    overflow-x: hidden;
  }
  
  .intro__interior__text__a {
    border-right: 1px solid;
    animation: typing__a 3s steps(13, end) 1s, blink-caret 0.75s step-end;
  }
  
  .intro__interior__text__b {
    animation: typing__b 3s steps(18, end) 4s, blink-caret 0.75s step-end;
  }
  
  .intro__interior__text__c {
    animation: typing__c 3s steps(15, end) 7s, blink-caret 0.75s step-end;
  }
  
  .intro__interior__text__d {
    animation: typing__d 3s steps(15, end) 10s, blink-caret 0.75s step-end infinite;
  }
  
  .animation {
    -webkit-animation-fill-mode: forwards;
    /* Chrome 16+, Safari 4+ */
    -moz-animation-fill-mode: forwards;
    /* FF 5+ */
    -o-animation-fill-mode: forwards;
    /* Not implemented yet */
    -ms-animation-fill-mode: forwards;
    /* IE 10+ */
    animation-fill-mode: forwards, infinite;
    /* When the spec is finished */
  }
  
  @keyframes intro__exterior {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(90deg);
    }
    45% {
      transform: rotate(90deg);
      width: 120px;
    }
    50% {
      transform: rotate(90deg);
      width: 337px;
    }
    70% {
      transform: rotate(90deg);
      width: 337px;
      height: 222px;
    }
    75% {
      transform: rotate(0deg);
      width: 337px;
      height: 222px;
    }
    95% {
      transform: rotate(0deg);
      width: 337px;
      height: 222px;
      border-radius: 15px;
    }
    100% {
      transform: rotate(0deg);
      width: 100%;
      height: 100%;
      border-radius: 0px;
    }
  }
  @keyframes intro__interior {
    0% {
      transform: rotate(0deg);
    }
    95% {
      transform: rotate(0deg);
      width: 95%;
      height: 95%;
      border-radius: 15px;
    }
    100% {
      transform: rotate(0deg);
      width: 100%;
      height: 100%;
      border-radius: 0px;
    }
  }
  @keyframes intro {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes intro__interior__text {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(-90deg);
    }
    45% {
      transform: rotate(-90deg);
    }
    50% {
      transform: rotate(-90deg);
    }
    70% {
      transform: rotate(-90deg);
    }
    75% {
      transform: rotate(0deg);
    }
    95% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  @keyframes blink-caret {
    from, to {
      border-color: transparent;
    }
    50% {
      border-color: orange;
    }
  }
  @keyframes typing__a {
    0% {
      width: 0;
      border-right: 1px solid;
    }
    30% {
      width: 82px;
      border-right: 1px solid;
    }
    70% {
      width: 82px;
      border-right: 1px solid;
    }
    80% {
      width: 0;
      border-right: 1px solid;
    }
    99.9% {
      width: 0;
      border-right: 1px solid;
    }
    100% {
      width: 0;
      border-right: none;
    }
  }
  @keyframes typing__b {
    0% {
      width: 0;
      border-right: 1px solid;
    }
    30% {
      width: 146px;
      border-right: 1px solid;
    }
    70% {
      width: 146px;
      border-right: 1px solid;
    }
    80% {
      width: 0;
      border-right: 1px solid;
    }
    99.9% {
      width: 0;
      border-right: 1px solid;
    }
    100% {
      width: 0;
      border-right: none;
    }
  }
  @keyframes typing__c {
    0% {
      width: 0;
      border-right: 1px solid;
    }
    30% {
      width: 111px;
      border-right: 1px solid;
    }
    70% {
      width: 111px;
      border-right: 1px solid;
    }
    80% {
      width: 0;
      border-right: 1px solid;
    }
    99.9% {
      width: 0;
      border-right: 1px solid;
    }
    100% {
      width: 0;
      border-right: none;
    }
  }
  @keyframes typing__d {
    0% {
      width: 0;
      border-right: 1px solid;
    }
    30% {
      width: 126px;
      border-right: 1px solid;
    }
    70% {
      width: 126px;
      border-right: 1px solid;
    }
    80% {
      width: 0;
      border-right: 1px solid;
    }
    90% {
      width: 0;
      border-right: 1px solid;
    }
    100% {
      width: 0;
      border-right: none;
    }
  }
  
// //   html, body {
// //     font-family: sans-serif;
// //     margin: 0;
// //     padding: 0;
// //     height: 100%;
// //     background-color: #333333;
// //     color: #DDDDDD;
// //     overflow-x: hidden;
// //   }
  
//   body {
//     opacity: 0;
//     animation: fadeIn 1s ease;
//   }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }