@import '../params/index.scss'; 

.content {
    position: absolute;
    top: 72%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 160px;
    overflow:hidden;
    // max-width: 200px;
    font-family: 'Lato', sans-serif;
    font-size: 35px;
    line-height: 40px;
    color: #ecf0f1;
    
    @media(max-width: 480px) {
      font-size: 25px;
    }

    &__container {
      font-weight: 600;
      overflow: hidden;
      height: 40px;
      padding: 0 40px;
  
      @media(max-width: 480px) {
        display: flex;
        flex-direction: column;
        padding: 0 20px;
      }
      
      // &:before {
      //   content: '[';
      //   left: 0;
      // }
  
      // &:after {
      //   content: ']';
      //   position: absolute;
      //   right: 0;
      // }
  
      // &:after, &:before {
      //   position: absolute;
      //   top: 0;
        
      //   color: $color2;
      //   font-size: 42px;
      //   line-height: 40px;
        
      //   -webkit-animation-name: opacity;
      //   -webkit-animation-duration: 2s;
      //   -webkit-animation-iteration-count: infinite;
      //   animation-name: opacity;
      //   animation-duration: 2s;
      //   animation-iteration-count: infinite;
      // }
  
      &__text {
        display: inline;
        float: left;
        margin: 0;
      }
  
      &__list {
        margin-top: 3px;
        padding-left: 120px;
        text-align: right;
        list-style: none;
        
        -webkit-animation-name: change;
        -webkit-animation-duration: 20s;
        -webkit-animation-iteration-count: infinite;
        animation-name: change;
        animation-duration: 20s;
        animation-iteration-count: infinite;
  
        @media(max-width: 480px) {
          padding-left: 200px;
        }


        &__item {
          line-height:40px;
          margin:0;
        }
      }
    }
  }
  
  // @-webkit-keyframes opacity {
  //   0%, 100% {opacity:0;}
  //   50% {opacity:1;}
  // }
  
  @-webkit-keyframes change {
    0%, 12.66%, 100% {transform:translate3d(0,0,0);}
    16.66%, 29.32% {transform:translate3d(0,-25%,0);}
    33.32%,45.98% {transform:translate3d(0,-50%,0);}
    49.98%,62.64% {transform:translate3d(0,-75%,0);}
    66.64%,79.3% {transform:translate3d(0,-50%,0);}
    83.3%,95.96% {transform:translate3d(0,-25%,0);}
  }
  
  @-o-keyframes opacity {
    0%, 100% {opacity:0;}
    50% {opacity:1;}
  }
  
  @-o-keyframes change {
    0%, 12.66%, 100% {transform:translate3d(0,0,0);}
    16.66%, 29.32% {transform:translate3d(0,-25%,0);}
    33.32%,45.98% {transform:translate3d(0,-50%,0);}
    49.98%,62.64% {transform:translate3d(0,-75%,0);}
    66.64%,79.3% {transform:translate3d(0,-50%,0);}
    83.3%,95.96% {transform:translate3d(0,-25%,0);}
  }
  
  @-moz-keyframes opacity {
    0%, 100% {opacity:0;}
    50% {opacity:1;}
  }
  
  @-moz-keyframes change {
    0%, 12.66%, 100% {transform:translate3d(0,0,0);}
    16.66%, 29.32% {transform:translate3d(0,-25%,0);}
    33.32%,45.98% {transform:translate3d(0,-50%,0);}
    49.98%,62.64% {transform:translate3d(0,-75%,0);}
    66.64%,79.3% {transform:translate3d(0,-50%,0);}
    83.3%,95.96% {transform:translate3d(0,-25%,0);}
  }
  
  @keyframes opacity {
    0%, 100% {opacity:0;}
    50% {opacity:1;}
  }
  
  @keyframes change {
    0%, 12.66%, 100% {transform:translate3d(0,0,0);}
    16.66%, 29.32% {transform:translate3d(0,-25%,0);}
    33.32%,45.98% {transform:translate3d(0,-50%,0);}
    49.98%,62.64% {transform:translate3d(0,-75%,0);}
    66.64%,79.3% {transform:translate3d(0,-50%,0);}
    83.3%,95.96% {transform:translate3d(0,-25%,0);}
  }
  @keyframes change_movil {
    0%, 12.66%, 100% {transform:translate3d(0,0,0);}
    16.66%, 29.32% {transform:translate3d(0,-25%,0);}
    33.32%,45.98% {transform:translate3d(0,-50%,0);}
    49.98%,62.64% {transform:translate3d(0,-75%,0);}
    66.64%,79.3% {transform:translate3d(0,-50%,0);}
    83.3%,95.96% {transform:translate3d(0,-25%,0);}
  }