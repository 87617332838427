@import './componentes/Services.scss';
@import './componentes/Three.scss';
@import './componentes/Loading.scss';
@import './componentes/Title.scss';
@import './componentes/Menu.scss'; 
@import './componentes/Responsive.scss'; 
@import './componentes/Marca.scss'; 
@import './componentes/Formulario.scss'; 
@import './componentes/Star.scss'; 
@import './params/index.scss'; 


body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $background;
  color: $colorText!important;
}

code {
  font-family: 'Source Serif Pro', serif;
  font-size: 40px;
}

.app {
  height: 100vh;
  position: relative;
  overflow: hidden;
  .navbar {
    justify-content: center;
  }
  @media(max-width: 460px) {
    overflow: auto;
    overflow-x: hidden;
    height: 100%;
  }

}

.home, .service, .contact {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c_idiomas {
  position: absolute;
  right: 0px;
  top: 20px;
  z-index: 1000;

  @media(max-width: 460px) {
    right: 30px;
    top: 10px;
  }

  button {
    background: transparent;
    border: none;
    color: #ffff;
    font-size: 20px;
    z-index: 1000;
    border-bottom: 3px solid transparent;
    padding: 0px;
    margin-left: 15px;
    font-family: "Lato", sans-serif;
  }
  .active {
    border-bottom: 3px solid #fff;
  }
}

.c_home_idiomas {
  .c_idiomas {
    right: -12px;
    @media(max-width: 460px) {
      right: 20px;
      top: 10px;
    }
  }
}


ul {
  list-style:none;
  text-align: end;
  li {
    a {
      color: $colorText;
      &:hover {
        color: $color2
      }
    }
  }
}


