@import '../params/index.scss'; 

.contacto {
    .form {
        max-width: 600px;
        margin: auto;
        margin-top: 30px;
        @media(max-width: 480px) {
            padding: 0px 20px;
        }
        input, textarea {
            width: 100%;
            background: rgba(255, 255, 255, 0.1);
            border: 1px solid rgba(255,255, 255, 0.5)!important;
            color: $colorText
        }
        textarea {
            height: 200px;
            @media(max-width: 480px) {
                height: 150px;
            }
        }
        button {
            float: left;
            background: $color2;
            padding: 10px 30px;
            border: 1px solid $color2!important;
            outline: none;
            &:hover {
                background: rgba(255, 255, 255, 0.1);
                color: $colorText
            }
        }
    }

    .cont-marca{
        @media(max-width: 460px) {
            right: -5px;
            margin-top: 20px;
        }
    }
}


.plane {
width:350px;
height:250px;
display:grid;
transform-style:preserve-3d;
transform:rotateX(0deg) rotate(0deg);
animation:n4 1.8s linear both 3s;
margin: auto;
margin-top: 80px;
margin-bottom: 110px;

.img-plane {
    width: 100%;
    max-width: 200px;
    margin: auto;
}
}

.mensaje-success {
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
        text-align: center;
        font-size: 3rem;
    }
}

.transform {
    transform: translateX(-185px) translateY(-110px) translateZ(0px)!important;
}