.service {
    .container {
        padding: 0;
        .left {
            text-align: left;
            border-bottom: 1px solid rgba(255, 255, 255, 0.4);
            padding-top: 10px;
            position: relative;
            span {
                font-size: 2.5rem;
                font-family: 'Lato', sans-serif;
                
            }

            @media(max-width: 480px) { 
                padding: 0px 30px;
                margin-top: 20px;
                border-top: 1px solid rgba(255, 255, 255, 0.4);
                border-bottom: 0;
                span {
                    font-size: 1.8rem;
                    line-height: 50px;
                }
            }
        }
        .right {
            text-align: left;
            padding: 60px 0px 160px;
            position: relative;
            min-height: 550px;
            @media(max-width: 480px) {
                padding: 20px 0px;
            }
            .fig-back {
                position: relative;
                display: flex;
                align-items: center;
                .fig {
                    perspective: 1400px;

                    @media(max-width: 480px) {
                        width: 100%;
                    }
                }
                  
                .fig .box-img {
                    transform: rotateX(350deg) rotateY(60deg);
                    border-radius: 0.25em;
                    filter: brightness(0.7);

                    // img {
                    //     box-shadow: 0.5px 20px 50px hsla(0,0%,0%,0.50);
                    // }

                    @media(max-width: 480px) {
                        transform: none;
                        padding: 10px 20px;
                        img {
                            width: 100%;
                            object-fit: contain;
                            height: 250px;
                        }
                    }
                }
            }

            .box-text-serv {
                padding: 20px;
                p {
                    font-size: 1.5rem;
                    // text-transform: uppercase;
                    line-height: 30px;
                    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
                    text-align: right;

                    @media(max-width: 480px) {
                        font-size: 1.2rem;
                        line-height: 25px;
                        text-align: left;
                    }
                }
                @media(max-width: 480px) {
                    padding-top: 0px;
                }
            }
        }
    }

}