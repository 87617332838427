.navbar {

    a {
        color: $colorText;
        text-decoration: none;
        margin: 10px 12px;
        font-family: 'Lato', sans-serif;
        font-size: 18px;
        font-weight: lighter;

        @media(max-width: 480px) {
            line-height: 30px
        }
     
        &:hover {
            color: $color2
        }

    }

    .activeLink {
        color: $color2!important
    }

    bottom: 0px;
    height: 60px;
    z-index: 1000;
    position: fixed!important;
    bottom: 0;
    width: 100%;

    @media(max-width: 460px) {
        background: #000;
        height: fit-content;
    }

}

